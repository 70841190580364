import { cleanUrlName } from "~/utils/strings.utils";

export const isRouteMatched = (name) => {
  return name === cleanUrlName(useRoute().name);
};

export const isTimePassed = (endAt) => {
  return endAt < new Date().getTime() / 1000;
};

export const isPromotionEnded = (category) => {
  return isTimePassed(category.promotion.period.endAt);
};
